import React, { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress } from '@mui/material';
import sevakilogoloader from '../Assets/Images/sevakilogoloader.svg';
import { Context } from '../context/Context';

const Loader = () => {
    const [progress, setProgress] = useState(0);
    const { defaultimg } = useContext(Context)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                return Math.min(oldProgress + 10, 100);
            });
        }, 90);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            height: '100vh', flexDirection: 'column', bgcolor: 'white', overflow: 'hidden'
        }} >
            <Box sx={{ marginTop: 0.5, width: '150px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} >
                <CircularProgress variant="determinate" value={progress} size={150} thickness={1}
                />
                <Box position="absolute" top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)', }} >
                    <img loading="lazy" src={sevakilogoloader} alt={defaultimg} width="80" height="40" />
                </Box>
            </Box>
        </Box>
    );
};

export default Loader;

