import React, { useContext, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Box } from '@mui/material';
import { Context } from '../context/Context';

const Error = () => {
    const { error, openErrorBar, setOpenErrorBar } = useContext(Context);

    useEffect(() => {
        const timer = setTimeout(() => { setOpenErrorBar(false); }, 2000);

        return () => clearTimeout(timer);
    }, [openErrorBar, error]);

    return (
        // <Snackbar
        //     open={locationPermissionDenied}
        //     autoHideDuration={6000}
        //     onClose={() => setLocationPermissionDenied(false)}
        //     message="Location permission is required to proceed."
        // />

        <Snackbar
            open={openErrorBar}
            autoHideDuration={2000}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px' }}
        >
            <Box sx={{ padding: '16px', display: 'flex', alignItems: 'center', border: "1px solid #E4E4E4" }}>
                <Box>{error}</Box>
            </Box>
        </Snackbar>
    );
};

export default Error;
